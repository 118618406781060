<template>
    <nav class="sidebar">
        <el-menu
            router
            unique-opened
            show-timeout="5000"
            hide-timeout="5000"
            style="overflow:auto;"
            class="el-menu-vertical"
            :collapse="isCollapse"
            :default-active="$route.path"
            @select="selectMenu"
        >
            <template v-for="item in sideBarRoutes">
                <template v-if="item.children">
                    <el-submenu :index="item.path" :key="item.id">
                        <template slot="title">
                            <i class="iconfont" :class="item.css"></i>
                            <span slot="title">{{ item.meta.title }}</span>
                        </template>
                        <template v-for="subItem in item.children">
                            <el-menu-item :index="subItem.path" :key="subItem.id">
                                <i class="iconfont iconjiantouyou" :class="subItem.cssName"></i>
                                <span class="navTitle">
                                    {{ subItem.meta.title }}
                                </span>
                            </el-menu-item>
                        </template>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="item.path" :key="item.path">
                        <i class="iconfont" :class="item.css"></i>
                        <span slot="title">{{ item.meta.title }}</span>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
    </nav>
</template>
<script>
export default {
    props: {
        sideBarRoutes: {
            type: [Array, Object],
            required: true, // 是否必传
        },
        isCollapse: {
            type: Boolean,
            required: true, // 是否必传
        },
    },
    data() {
        return {};
    },
    methods: {
        selectMenu(path, allPath) {
            const secondaryMenu = this.sideBarRoutes.filter(item => item.path === allPath[0])[0];
            const currentMenu = secondaryMenu.children.filter(item => item.path === path)[0];
            this.$emit('addNavTabs', currentMenu);
        },
    },
};
</script>
<style lang="stylus">
    .sidebar
        height calc(100% - 0.52rem)
        display block
        background #fff
        .el-menu
            width 100%
            height 100%
            border-right none
            background none
            overflow-x hidden !important
            &.el-menu--collapse
                width 0.64rem
            .sidebar-el-menu:not(.el-menu--collapse)
                width 2.5rem
            .el-submenu
                width 2.4rem
                .el-submenu__title
                    font-size 0.18rem
                    font-weight 400
                    color #717E89
                    line-height 0.5rem
                    height 0.56rem
                    padding 0 0.15rem
                    display flex
                    align-items center
                    white-space initial
                    &:hover
                        // color #006EFF
                        background: none
                        // .el-icon-arrow-down,
                        // .iconfont
                        //     color #006EFF
                        >span,>i
                            color #006EFF
                            background: none
                    .el-submenu__icon-arrow
                        font-size 0.18rem
                        right 0.15rem
                .el-menu
                    .el-menu-item
                        position: relative
                        &:after
                            content ''
                            height: 100%
                            width: 2px
                            background: #E3EAF0
                            position: absolute
                            top 0
                            left 0.2rem
                        &.is-active
                            &:after
                                background: #006EFF
                &.is-opened
                    >.el-submenu__title
                        >span,>i
                            color #364456
                            font-weight: 600;
            .el-menu-item
                width 2.4rem
                font-size 0.18rem
                font-weight 400
                color #717E89
                height auto
                min-height 0.56rem
                line-height 0.24rem
                padding 0.1rem 0.2rem 0.1rem 0.45rem
                display flex
                align-items center
                white-space initial
                i
                    color #717E89
                    display flex
                    height 100%
                    align-items center
                &.is-active
                    background: linear-gradient(270deg, rgba(255, 255, 255, 0.1) 0%, rgba(0, 110, 255, 0.1) 100%);
            .iconfont
                font-size 0.2rem
                margin-right 0.1rem
                color #717E89
    .sidebar::-webkit-scrollbar
        width 0
</style>
